import React, { Component } from 'react';
import './sass/App.scss';
import { gsap } from 'gsap';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Why from './components/Why';
import What from './components/What';
import Who from './components/Who';
import Where from './components/Where';
import Work from './components/Work';
import AnimatedLoader from './components/AnimatedLoader'; // Adjust path as necessary


class App extends Component {
  state = {
    videosLoaded: false,
    isLandscape: true,
    showLoader: true,
  };

  componentDidMount() {
    this.loadVideos();
    this.handleOrientationChange(); // Call initially to set correct orientation
    window.addEventListener('orientationchange', this.handleOrientationChange);
    window.addEventListener('resize', this.handleOrientationChange); // Listen for resize events
  }
  
  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
    window.removeEventListener('resize', this.handleOrientationChange); // Clean up resize listener
  }

  handleOrientationChange = () => {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    this.setState({ isLandscape });
  };

  loadVideos = () => {
    const video1 = new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = './assets/video/vx01_vxBoxVideo-2024_EN.mp4';
      video.addEventListener('loadeddata', () => {
        resolve();
      });
      video.addEventListener('error', (error) => {
        console.error('Error loading video 1:', error);
        reject(error);
      });
      video.load();
    });
  
    const video2 = new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = './assets/video/vx01_vxBoxVideo-2024_EN_mobile.mp4';
      video.addEventListener('loadeddata', () => {
        resolve();
      });
      video.addEventListener('error', (error) => {
        console.error('Error loading video 2:', error);
        reject(error);
      });
      video.load();
    });
  
    Promise.all([video1, video2]).then(() => {
      // Simulate a minimum loading time of 2 seconds
      setTimeout(() => {
        this.setState({ videosLoaded: true, showLoader: false });
        // Trigger GSAP animation to fade in the videos
        gsap.fromTo('#landscape-video, #portrait-video', { opacity: 0 }, { opacity: 1, duration: 1 });
      }, 2500); // 2000 milliseconds = 2 seconds
    }).catch((error) => {
      console.error('Error loading videos:', error);
      // Handle error loading videos
      this.setState({ showLoader: false }); // Hide loader on error
    });
  };

  render() {
    const { videosLoaded, isLandscape } = this.state;
  
    if (!videosLoaded) {
      return <AnimatedLoader />;
    }
  
    return (
      <Router>
        <div className="App">
          <div id="video-container">
            <video id="landscape-video" autoPlay loop muted className={`background-video ${isLandscape ? 'visible' : 'hidden'}`}>
              <source src="assets/video/vx01_vxBoxVideo-2024_EN.mp4" type="video/mp4" />
            </video>
            <video id="portrait-video" autoPlay loop muted className={`background-video ${isLandscape ? 'hidden' : 'visible'}`}>
              <source src="assets/video/vx01_vxBoxVideo-2024_EN_mobile.mp4" type="video/mp4" />
            </video>
          </div>
      
          <Route exact path="/" component={What} />
          <Route exact path="/why" component={Why} />
          <Route exact path="/who" component={Who} />
          <Route exact path="/work" component={Work} />
          <Route exact path="/where" component={Where} />
        </div>
      </Router>
    );
  }
}

export default App;