import React, { useRef, useEffect, useState } from 'react';
import '../sass/App.scss';
import '../sass/Brands.scss';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const logos = [
    { src: '/assets/logos/align.png', alt: 'Align' },
    { src: '/assets/logos/jnj.png', alt: 'Johnson & Johnson' },
    { src: '/assets/logos/lifescan.png', alt: 'LifeScan' },
    { src: '/assets/logos/nevro.png', alt: 'Nevro' },
    { src: '/assets/logos/specsavers.png', alt: 'Specsavers' },
    { src: '/assets/logos/truearth.png', alt: 'Truearth' }
];

const What = React.forwardRef((props, ref) => {
    const [animationsDone, setAnimationsDone] = useState({
        heading1: false,
        description: false,
        services: false,
        brands: false
    });

    // Refs for elements to animate
    const heading1Ref = useRef(null);
    const descriptionRef = useRef(null);
    const servicesRef = useRef(null);
    const brandsRef = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3 // Adjust as needed
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !animationsDone[entry.target.id]) {
                    setAnimationsDone(prevState => ({
                        ...prevState,
                        [entry.target.id]: true
                    }));

                    gsap.from(entry.target, { x: 200, opacity: 0, duration: 0.75, delay: 0.25 });

                    // Disconnect observer for this entry once animation is triggered
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);

        if (heading1Ref.current) {
            observer.observe(heading1Ref.current);
        }
        if (descriptionRef.current) {
            observer.observe(descriptionRef.current);
        }
        if (servicesRef.current) {
            observer.observe(servicesRef.current);
        }
        if (brandsRef.current) {
            observer.observe(brandsRef.current);
        }

        return () => {
            observer.disconnect(); // Disconnect the observer on unmount
        };
    }, []); // Empty dependency array ensures effect runs only once

    return (
        <div className="whatFull" ref={ref}>
            < Navigation/>
            <div className="scroller">
                <section className="whatMain">
                    <h2>what</h2>
                    <div className="wrapper" >
                        <h6 ref={heading1Ref}>viv<span>id</span>fix is a creative design studio.</h6>   
                    </div>
                    <svg className="bouncer" version="1.1" x="0px" y="0px"
                        viewBox="0 0 127.7 280.1" >
                        <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                        <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                        <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                    </svg> 
                </section>
                <section className="whatDescription" ref={descriptionRef}>
                    <div className="wrapper">
                        <h1>full</h1>
                        <h1>graphic</h1>
                        <div className="servicePlus">
                            <h1>services</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <h1>prov<span>id</span>er</h1>
                    </div>
                    <svg className="bouncer" version="1.1" x="0px" y="0px"
                        viewBox="0 0 127.7 280.1" >
                            <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                            <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                            <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                    </svg>
                </section>
                <section className="whatServices" ref={servicesRef}>
                    <div className="wrapper">
                        <ul>
                            <li>
                                <h6 className="services">Brand Design</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Corporate <span>Identity</span></h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Creative Direction</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Email Campaigns</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Interactive Apps</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Project Management</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Promotional Materials</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Video Production</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li>
                                <h6 className="services">Web Development</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                        </ul>
                    </div>
                </section>
                {/* <section className="whatBrands" ref={brandsRef}>
                    <div className="wrapper">
                        <h6 className="brands">Trusted by<br/> great brands</h6>
                        <div className="logo-grid">
                            {logos.map((logo, index) => (
                                <div key={index} className='logo-item'>
                                    <img src={process.env.PUBLIC_URL + logo.src} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section> */}
                <Footer />       
            </div>
        </div>
    );
});

export default What;