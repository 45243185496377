import React from 'react';
import '../sass/App.scss';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const Why = () => {
    return (
        <div className="whyFull">
            < Navigation/>
            <div className="scroller">
                <section className="why">
                    <h2>why</h2>
                    <div className="wrapper">
                        <h6>viv<span>id</span>fix is modular.</h6>
                    </div>
                    <svg className="bouncer" version="1.1" x="0px" y="0px"
                        viewBox="0 0 127.7 280.1" >
                        <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                        <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                        <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                    </svg> 
                </section>
                <section className="whyDefinition">
                    <div className="wrapper">
                        <h6><span>[</span>mo<span className="dots">&#9679;</span>du<span className="dots">&#9679;</span>lar<span>]</span></h6>
                        <p className="type">context</p>
                        <p>A proven approach to design that can be connected into your organization for easy, immediate production and flexible arrangements. Plug the studio in</p>
                        <p>and we&rsquo;ll help you:</p>
                    </div>
                    <svg className="bouncer" version="1.1" x="0px" y="0px"
                        viewBox="0 0 127.7 280.1" >
                            <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                            <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                            <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                    </svg>
                </section>
                <section className="whyAnswer">
                    <div className="wrapper">
                        <h1>look good</h1>
                        <svg version="1.1" x="0px" y="0px"
                            viewBox="0 0 127.7 280.1" >
                            <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                            <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                            <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                        </svg>           
                    </div>
                </section>
                <Footer />       
            </div>
        </div>
    )
}

export default Why;