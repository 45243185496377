import React from 'react';
import '../sass/App.scss';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const Where = () => {
    return (
        <div className="whereFull">
            < Navigation/>
            <div className="scroller">
                <section className="whereMain">
                    <h2>where</h2>
                    <div className="wrapper">
                        <h6>Viv<span>id</span>fix is located in toronto, canada.</h6>
                    </div>
                    <svg className="bouncer" version="1.1" x="0px" y="0px"
                        viewBox="0 0 127.7 280.1" >
                        <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                        <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                        <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                    </svg> 
                </section>
                <section className="whereNumber">
                    <div className="wrapper">
                        <h1>416</h1>
                        <h1>503</h1>
                        <h1>0744</h1>
                        <h6>design@viv<span>id</span>fix.com</h6>
                    </div>
                    <svg className="bouncer" version="1.1" x="0px" y="0px"
                        viewBox="0 0 127.7 280.1" >
                            <path d="M12.5,108.5C4.9,108.5,0,103,0,95.2c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,103,19.4,108.5,12.5,108.5z"/>
                            <path d="M12.5,198.3C4.9,198.3,0,192.8,0,185c0-7,4.9-13.3,12.5-13.3c7,0,12.7,6.4,12.7,13.3 C25.2,192.8,19.4,198.3,12.5,198.3z"/>
                            <path d="M62.3,0c42.3,31.9,65.5,86.6,65.5,140.5c0,53-23.2,108.1-65.5,139.6l-2.9-3.8c31-27.8,48.7-79.1,48.7-136.1 c0-55-17.7-108.3-48.7-136.1L62.3,0z"/>
                    </svg>
                </section>
                <section className="whereAddress">
                    <div className="wrapper">
                        <h6>2733</h6>
                        <h6>lakeshore blvd west</h6>
                        <h6>suite 216</h6>
                        <h6>toronto ON M8V1G9</h6>
                    </div>
                </section>
                <Footer />       
            </div>
        </div>
    )
}

export default Where;