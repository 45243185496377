import React, { useEffect, useRef, useState } from 'react';
import '../sass/AnimatedLoader.scss'; // Adjust the path as per your project structure

const AnimatedLoader = () => {
  const [loadingState, setLoadingState] = useState(0); // State to track current loading state
  const [faceExpression, setFaceExpression] = useState(':)');
  const faceRef = useRef(null);
  const winkIntervalRef = useRef(null); // Ref to hold the wink interval

  // Define loading states
  const loadingStates = [
    'Loading images',
    'Loading videos',
    'Initializing color palette',
    'Setting up animations',
    'Loading fonts',
    'Optimizing performance',
    'Preparing content',
    'Polishing design',
    'Initializing'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingState((prevState) => {
        const nextState = prevState + 1;
        if (nextState >= loadingStates.length) {
          clearInterval(interval); // Stop the interval after reaching the end of loadingStates
        }
        return nextState;
      });
    }, 200); // Change every 0.2 seconds

    return () => {
      clearInterval(interval); // Cleanup function to clear interval
      if (winkIntervalRef.current) {
        clearInterval(winkIntervalRef.current); // Cleanup function to clear wink interval if it exists
      }
    };
  }, []);

  useEffect(() => {
    // Toggle between :) and ;) once we reach "Initializing"
    if (loadingStates[loadingState] === 'Initializing') {
      winkIntervalRef.current = setInterval(() => {
        setFaceExpression((prevExpression) => (prevExpression === ':)' ? ';)' : ':)'));
      }, 500); // Change every 0.5 seconds

      // Clear wink interval after 1 second
      setTimeout(() => {
        if (winkIntervalRef.current) {
          clearInterval(winkIntervalRef.current);
        }
      }, 1000); // Stop winking after 1 second
    }
  }, [loadingState]);

  useEffect(() => {
    // Reset smiley face whenever loading state changes
    if (faceRef.current) {
      faceRef.current.textContent = faceExpression;
    }
  }, [faceExpression]);

  return (
    <div className="preloader">
      <div class="faceExpression" ref={faceRef}>
        {faceExpression}
      </div>
      <p style={{ fontFamily: 'Minion Pro Display, serif', color: '#ffffff' }}>
      {loadingStates[loadingState]}
      </p>
    </div>
  );
};

export default AnimatedLoader;
