import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../sass/App.scss';

class Navigation extends Component {

    handleClick = (e) => {
        e.preventDefault();
    }

    render() {
        return (
            <div className="wrapper">
                <nav>
                    <Link className="navButton" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 570.12 132.83">
                            <g id="vxLogo" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path d="M0,33.88H23.11l24.41,74h.37l23.48-74h22L58.89,130.23H35Z"/>
                                    <path d="M103.05,0H124.3V20.13H103.05Zm0,33.88H124.3v96.35H103.05Z"/>
                                    <path d="M134.18,33.88h23.1l24.42,74h.37l23.48-74h22l-34.47,96.35H169.21Z"/>
                                    <path className="id" d="M238.12,0h21.25V20.13H238.12Zm0,33.88h21.25v96.35H238.12Z"/>
                                    <path className="id" d="M365.78,130.23H345.66v-13h-.38c-5.59,11-17.7,15.65-29.82,15.65-30.37,0-45.09-22.54-45.09-51.43,0-34.85,20.68-50.13,41.74-50.13,12.11,0,25.53,4.47,32.05,15.1h.38V0h21.24Zm-47.52-14.17c19,0,27-17.33,27-34.1C345.28,60.71,335,48,318.63,48c-19.94,0-27,17.71-27,35C291.61,99.66,299.81,116.06,318.26,116.06Z"/>
                                    <path d="M376.55,33.88h15.84V28.7C392.39,4.1,408.08,0,421.87,0h9.84V20.13h-7.64c-5.78,0-10.44,2.05-10.44,10.06v3.69h18.08V54H413.63v76.22H392.39V54H376.55Z"/>
                                    <path d="M445.31,0h21.25V20.13H445.31Zm0,33.88h21.25v96.35H445.31Z"/>
                                    <polygon points="570.12 33.88 546.04 33.88 523.44 65.31 500.84 33.88 476.76 33.88 511.4 82.05 476.76 130.23 500.84 130.23 523.44 98.8 546.04 130.23 570.12 130.23 535.48 82.05 570.12 33.88"/>
                                </g>
                            </g>
                        </svg>
                    </Link>        
                    <ul className="navList">
                        <li><Link className="whatLink" to="/">What</Link></li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                        <li>
                            <Link className="whyLink" to="/why">Why</Link>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                        <li><Link className="workLink" to="/work">Work</Link></li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                        <li><Link className="whereLink" to="/where">Where</Link></li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 500.03">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path d="M293.31,0H167.39V186.35H96.65c-53.52,0-96.65,19-96.65,93.18v34.15H167.39V500H293.31V313.68H490V186.35H293.31Z"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                        <li><Link className="whoLink" to="/who">Who</Link></li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navigation;